import { useAuthStore, useAuthFakeStore } from "@/state/pinia";
export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Visa Cases", authRequired: true },
    component: () => import("../views/home/index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      title: "Forgot password",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        const authFake = useAuthFakeStore();

        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          auth.logOut();
        } else {
          authFake.logout();
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      }
    }
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "404 Error Page" },
    component: require("../views/utility/404").default
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/home/list",
    name: "homelist",
    component: () => import("../views/home/list"),
  },
  {
    path: "/home/complete",
    name: "homecomplete",
    component: () => import("../views/home/completeList"),
  },
  // {
  //   path: "/visa/add",
  //   name: "addRecord",
  //   component: () => import("../views/visa/addRecord"),
  // },
  // {
  //   path: "/case/visa/mine",
  //   name: "myCases",
  //   component: () => import("../views/visa/my-case"),
  // },
  // {
  //   path: "/visa/track",
  //   name: "trackRecord",
  //   component: () => import("../views/visa/track"),
  // },
  {
    path: "/visa/detail",
    name: "detailRecord",
    component: () => import("../views/visa/detailRecord"),
  },
  {
    path: "/account/user-active",
    name: "user-active",
    component: () => import("../views/account/user-active"),
  },

  {
    path: "/report/list",
    name: "report-list",
    meta: {authRequired: true},
    component: () => import("../views/report/list"),
  },
  {
    path: "/report/detail",
    name: "reportdetail",
    component: () => import("../views/report/detail"),
  },
  {
    path: "/blog/grid",
    name: "blog-grid",
    meta: {authRequired: true},
    component: () => import("../views/blog/grid"),
  },
  {
    path: "/blog/detail",
    name: "blog-detail",
    meta: {authRequired: true},
    component: () => import("../views/blog/detail"),
  },
  {
    path: "/pages/404",
    name: "Error-404",
    meta: {authRequired: true},
    component: () => import("../views/utility/404"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    meta: {authRequired: true},
    component: () => import("../views/utility/500"),
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: () => import("../views/utility/sitemap"),
  }
];
